.hbt-Heading {
  font-weight: var(--font-weight-semiBold);
}
.hbt-Heading.hbt-Heading--xxxs {
  font-size: var(--font-size-xxxs);
}
.hbt-Heading.hbt-Heading--xxs {
  font-size: var(--font-size-xxs);
}
.hbt-Heading.hbt-Heading--xs {
  font-size: var(--font-size-xs);
}
.hbt-Heading.hbt-Heading--sm {
  font-size: var(--font-size-sm);
}
.hbt-Heading.hbt-Heading--md {
  font-size: var(--font-size-md);
}
.hbt-Heading.hbt-Heading--lg {
  font-size: var(--font-size-lg);
}
.hbt-Heading.hbt-Heading--xl {
  font-size: var(--font-size-xl);
}
.hbt-Heading.hbt-Heading--xxl {
  font-size: var(--font-size-xxl);
}
.hbt-Heading.hbt-Heading--xxxl {
  font-size: var(--font-size-xxxl);
}
.hbt-Heading.hbt-Heading--ul {
  font-size: var(--font-size-ul);
}
.hbt-Heading.hbt-Heading--uul {
  font-size: var(--font-size-uul);
}

.hbt-Heading--inverse {
  color: var(--color-neutral-lightest);
}