.hbt-Link, .hbt-Topic {
  color: var(--color-neutral-darkest);
  text-decoration: none;
  font-weight: var(--font-weight-semiBold);
  align-items: center;
  display: flex;
}

.hbt-Link--inverse, .hbt-Topic--inverse {
  color: var(--color-neutral-lightest);
}

.hbt-Link:hover .hbt-Link-content {
  text-decoration: underline;
}

.hbt-Link .hbt-Link-icon,
.hbt-Topic .hbt-Topic-icon {
  margin-right: var(--spacing-inline-xxs);
  text-decoration: none;
}

.hbt-Link .hbt-Link-content+.hbt-Link-icon,
.hbt-Topic .hbt-Topic-content+.hbt-Topic-icon {
  margin-left: var(--spacing-inline-xxs);
}