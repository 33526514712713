.hbt-Banner {
  background-color: var(--color-brand-primary-medium);
  margin-bottom: var(--spacing-stack-lg);
  max-height: 610px;
  overflow: hidden;
}

.hbt-Banner .hbt-Banner-item {
  position: relative;
  max-height: 610px;
  overflow: hidden;
}

.hbt-Banner .hbt-Banner-item:after {
  content: " ";
  position: absolute;
  width: 100%;
  background: url('./wave.svg') no-repeat center bottom/100%;
  height: 100%;
  bottom: 0;
  left: 0;
}

.hbt-Banner .hbt-Banner-item .hbt-Banner-item-image {
  width: 100%;
  max-height: 60vh;
  object-fit: cover;
  display: flex;
  margin: auto;
  object-position: center;
}

.hbt-Banner .hbt-Banner-item .hbt-Banner-item-content {
  position: absolute;
  width: 60%;
  height: 100%;
  top: 45%;
  left: 35%;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}