.hbt-Form {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 1024px) {
  .hbt-Form {
    max-width: 50%;
  }
}

.hbt-Form .hbt-Form-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hbt-Input {
  display: flex;
  flex-direction: column;
  /* padding-bottom: var(--spacing-stack-md); */
  position: relative;
  margin-bottom: var(--spacing-stack-xs);
}

.hbt-Input .hbt-Input-inputField {
  height: 44px;
  font-size: var(--font-size-xs);
  border-radius: var(--border-radius-xs);
  border: var(--border-width-thin) solid var(--color-neutral-dark);
  padding: 0 var(--spacing-inline-xs);
}

.hbt-Input .hbt-Input-inputLabel {
  font-weight: var(--font-weight-semiBold);
  margin-bottom: var(--spacing-stack-xxxs);
}

.hbt-Input.hbt-Input--error .hbt-Input-errorMessage {
  display: block;
}

.hbt-Input .hbt-Input-errorMessage {
  margin-top: var(--spacing-stack-xxxs);
  margin-bottom: var(--spacing-stack-xxxs);
  color: red;
  font-size: var(--font-size-xxxs);
  display: none
}

.hbt-Form-submit {
  border-radius: var(--border-radius-pill);
  background-color: var(--color-brand-primary-medium);
  color: var(--color-neutral-lightest);
  border: none;
  font-size: var(--font-size-xs);
  display: flex;
  align-self: flex-end;
  padding: var(--spacing-squish-sm);
  font-weight: var(--font-weight-semiBold);
  cursor: pointer;
}

.hbt-Form-submit:disabled {
  cursor: not-allowed;
  background-color: var(--color-neutral-medium);
}

.hbt-Form-submit:hover {
  background-color: var(--color-brand-primary-dark);
}