.hbt-Article .hbt-Heading,
.hbt-Article .hbt-Subtitle {
  margin-bottom: var(--spacing-stack-xs);
}

.hbt-Article .hbt-Article-content {
  margin-bottom: var(--spacing-stack-md);
  /* justify-content: center;
  display: flex;
  flex-direction: column; */
}

.hbt-Article .hbt-Article-content .hbt-Text+.hbt-Text {
  margin-top: var(--spacing-stack-xxxs);
}