.hbt-Menu,
.hbt-Menu .hbt-Menu-left {
  display: flex;
  align-items: center;
}

.hbt-Menu {
  justify-content: space-between;
  flex-direction: row;
  height: 70px;
  background-color: var(--color-neutral-lightest);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  box-shadow: 0 0 40px -30px;
}

.hbt-Menu .hbt-Container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.hbt-Menu .hbt-Menu-left nav ul,
.hbt-Menu .hbt-Menu-right ul {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.hbt-Menu .hbt-Menu-left-logo {
  margin-right: var(--spacing-inline-xs);
  height: 50px;
}

.hbt-Menu .hbt-Menu-left-navigation.hbt-isActive {
  border-bottom: var(--border-width-thick) solid var(--color-brand-primary-dark);
  padding-top: var(--spacing-stack-xxxs);
}

.hbt-Menu .hbt-Menu-left nav ul li a,
.hbt-Menu .hbt-Menu-right ul li a {
  padding: var(--spacing-inset-md);
  display: flex;
}

@media screen and (max-width: 1024px){
  .hbt-Menu .hbt-Menu-left,
  .hbt-Menu .hbt-Container,
  .hbt-Menu .hbt-Menu-left nav ul,
  .hbt-Menu .hbt-Menu-right ul {
    flex-direction: column;
    align-items: normal;
  }

  .hbt-Menu > a {
    margin-left: var(--spacing-inline-xs);
  }

  .hbt-Menu .hbt-Container {
    background-color: var(--color-neutral-lightest);
    width: 70vw;
    position: fixed;
    right: -70vw;
    top: 0;
    height: 100%;
    box-shadow: 0 0 40px -30px;
    padding-top: 70px; /* Header height */
    transition: all 0.2s ease-in;
  }

  .hbt-Menu .hbt-Menu-toggleButton {
    background-color: var(--color-neutral-lightest);
    border: none;
    height: 100%;
    padding: var(--spacing-inset-md);
    font-size: var(--font-size-lg);
    position: relative;
    z-index: 20;
    color: var(--color-neutral-darkest);
  }
  .hbt-Menu .hbt-Menu-toggleButton:active {
    opacity: var(--opacity-medium);
  }

  .hbt-Menu .hbt-Menu-left-navigation.hbt-isActive {
    border-bottom: none;
  }

  .hbt-Menu .hbt-Menu-left-navigation.hbt-isActive a {
    color: var(--color-brand-primary-dark);
  }

  .hbt-Menu .hbt-Menu-container.hbt-isActive {
    right: 0;
  }

}