.hbt-Text {
  color: var(--color-neutral-dark);
  text-decoration: none;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-xxs);
  line-height: var(--font-line-height-xxs);
}

.hbt-Text--inverse {
  color: var(--color-neutral-lightest);
}

.hbt-Text--bold {
  font-weight: var(--font-weight-semiBold);
}