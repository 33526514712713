.hbt-ImageLink {
  display: flex;
  flex-direction: column-reverse;
  align-items: baseline;
  padding: var(--spacing-inset-xs);
  width: 357px;
  height: 238px;
  position: relative;
  border-radius: var(--border-radius-xs);
  overflow: hidden;
  border: var(--border-width-medium) solid transparent;
  transition: all ease-in 300ms;
}

.hbt-ImageLink:hover {
  /* border: var(--border-width-medium) solid var(--color-neutral-medium); */
}

.hbt-ImageLink:hover .hbt-ImageLink-img {
  transform: scale(1.02);
}

.hbt-ImageLink .hbt-ImageLink-img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
  object-position: top;
  transition: all ease-in 200ms;
}

.hbt-ImageLink .hbt-ImageLink-highlight {
  z-index: 2;
}

.hbt-ImageLink .hbt-ImageLink-highlight .hbt-Heading {  
  background-color: var(--color-neutral-darkest);
  padding: var(--spacing-inset-xs);
  border-radius: var(--border-radius-xs);
  display: flex;
}

.hbt-ImageLink .hbt-ImageLink-highlight .hbt-Heading .hbt-ImageLink-highlight-icon {
  padding-left: var(--spacing-inline-xxxs);
  transition: all 100ms ease-in;
}

.hbt-ImageLink:hover .hbt-ImageLink-highlight .hbt-Heading .hbt-ImageLink-highlight-icon {
  padding-left: var(--spacing-inline-xxs);
}