@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

body {
  position: relative;
  padding-top: 70px;
}

body, * {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

.material-icons.hbt-Link-icon.instagram {
  background: url(./instagram.svg) no-repeat center center;
  width: 24px;
  height: 24px;
  font-size: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
