.hbt-Home .hbt-Home-cards,
.hbt-NotFound .hbt-Home-cards {
  display: flex;
  justify-content: space-between;
}

.hbt-Home .hbt-Banner .hbt-Banner-item .hbt-Banner-item-image {
  object-position: bottom;
}

@media screen and (max-width: 1024px) {
  .hbt-Home .hbt-Home-cards,
  .hbt-NotFound .hbt-Home-cards {
    flex-direction: column;
    align-items: center;
  }

  .hbt-Home .hbt-Banner .hbt-Banner-item .hbt-Banner-item-content {
    /* transform: translate(-20%, -50%) scale(.8); */
    /* width: 90%; */
  }

  .hbt-Home .hbt-Home-cards .hbt-ImageLink,
  .hbt-NotFound .hbt-Home-cards .hbt-ImageLink {
    margin-bottom: var(--spacing-stack-md);
  }
}

@media screen and (max-width: 580px) {
  .hbt-Home .hbt-Banner .hbt-Banner-item .hbt-Banner-item-content {
    transform: translate(-20%, -50%) scale(.5);
    width: 90%;
  }
}