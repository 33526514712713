:root {
  --color-brand-primary-darkest: #67160D;
  --color-brand-primary-dark: #A61D19;
  --color-brand-primary-medium: #E3262E;
  --color-brand-primary-light: #FE918D;
  --color-brand-primary-lightest: #FDE6E3;
  --color-neutral-lightest: #FFFFFF;
  --color-neutral-light: #F8F8F8;
  --color-neutral-medium: #D4D7DE;
  --color-neutral-dark: #51555D;
  --color-neutral-darkest: #000000;
  --font-weight-semiBold: 600;
  --font-weight-regular: 400;
  --font-size-xxxs: 12px;
  --font-size-xxs: 16px;
  --font-size-xs: 18px;
  --font-size-sm: 20px;
  --font-size-md: 22px;
  --font-size-lg: 24px;
  --font-size-xl: 26px;
  --font-size-xxl: 28px;
  --font-size-xxxl: 32px;
  --font-size-ul: 40px;
  --font-size-uul: 64px;
  --font-line-height-none: 0;
  --font-line-height-xxxs: 16px;
  --font-line-height-xxs: 20px;
  --font-line-height-xs: 22px;
  --font-line-height-sm: 24px;
  --font-line-height-md: 26px;
  --font-line-height-lg: 28px;
  --font-line-height-xl: 30px;
  --font-line-height-xxl: 32px;
  --font-line-height-xxxl: 36px;
  --font-line-height-ul: 44px;
  --font-line-height-uul: 68px;
  --border-width-none: 0;
  --border-width-thin: 1px;
  --border-width-medium: 2px;
  --border-width-thick: 4px;  
  --border-radius-none: 0;
  --border-radius-xs: 4px;
  --border-radius-sm: 8px;
  --border-radius-md: 16px;
  --border-radius-lg: 24px;
  --border-radius-pill: 500px;
  --border-radius-circle: 50%;
  --spacing-inline-xxxs: 4px;
  --spacing-inline-xxs: 8px;
  --spacing-inline-xs: 16px;
  --spacing-inline-sm: 24px;
  --spacing-inline-md: 32px;
  --spacing-inline-lg: 48px;
  --spacing-inline-xl: 64px;
  --spacing-inset-xxs: 4px;
  --spacing-inset-xs: 8px;
  --spacing-inset-sm: 16px;
  --spacing-inset-md: 24px;
  --spacing-inset-lg: 32px;
  --spacing-inset-xl: 48px;
  --spacing-squish-xxxs: 4px 8px;
  --spacing-squish-xxs: 4px 16px;
  --spacing-squish-xs: 8px 16px;
  --spacing-squish-sm: 8px 24px;
  --spacing-squish-md: 16px 24px;
  --spacing-squish-lg: 16px 32px;
  --spacing-squish-xl: 24px 32px;
  --spacing-squish-xxl: 32px 48px;
  --spacing-stack-xxxs: 4px;
  --spacing-stack-xxs: 8px;
  --spacing-stack-xs: 16px;
  --spacing-stack-sm: 24px;
  --spacing-stack-md: 32px;
  --spacing-stack-lg: 48px;
  --spacing-stack-xl: 64px;
  --spacing-stack-xxl: 96px;
  --spacing-stack-xxxl: 128px;
}