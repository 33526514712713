.hbt-Subtitle {
  font-weight: var(--font-weight-semiBold);
  color: var(--color-neutral-dark);
}
.hbt-Subtitle.hbt-Subtitle--xxxs {
  font-size: var(--font-size-xxxs);
}
.hbt-Subtitle.hbt-Subtitle--xxs {
  font-size: var(--font-size-xxs);
}
.hbt-Subtitle.hbt-Subtitle--xs {
  font-size: var(--font-size-xs);
}
.hbt-Subtitle.hbt-Subtitle--sm {
  font-size: var(--font-size-sm);
}
.hbt-Subtitle.hbt-Subtitle--md {
  font-size: var(--font-size-md);
}
.hbt-Subtitle.hbt-Subtitle--lg {
  font-size: var(--font-size-lg);
}
.hbt-Subtitle.hbt-Subtitle--xl {
  font-size: var(--font-size-xl);
}
.hbt-Subtitle.hbt-Subtitle--xxl {
  font-size: var(--font-size-xxl);
}
.hbt-Subtitle.hbt-Subtitle--xxxl {
  font-size: var(--font-size-xxxl);
}
.hbt-Subtitle.hbt-Subtitle--ul {
  font-size: var(--font-size-ul);
}

.hbt-Subtitle.hbt-Subtitle--ul {
  font-size: var(--font-size-ul);
}

.hbt-Subtitle--inverse {
  color: var(--color-neutral-lightest);
}