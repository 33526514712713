@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap);
body {
  position: relative;
  padding-top: 70px;
}

body, * {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

.material-icons.hbt-Link-icon.instagram {
  background: url(/static/media/instagram.57e541b3.svg) no-repeat center center;
  width: 24px;
  height: 24px;
  font-size: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Material Icons Outlined";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(/static/media/material-icons-outlined.c32fc86b.woff2) format("woff2"), url(/static/media/material-icons-outlined.a55facf1.woff) format("woff");
}
.material-icons {
  font-family: "Material Icons Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: "liga";
}

.hbt-Home .hbt-Home-cards,
.hbt-NotFound .hbt-Home-cards {
  display: flex;
  justify-content: space-between;
}

.hbt-Home .hbt-Banner .hbt-Banner-item .hbt-Banner-item-image {
  object-position: bottom;
}

@media screen and (max-width: 1024px) {
  .hbt-Home .hbt-Home-cards,
  .hbt-NotFound .hbt-Home-cards {
    flex-direction: column;
    align-items: center;
  }

  .hbt-Home .hbt-Banner .hbt-Banner-item .hbt-Banner-item-content {
    /* transform: translate(-20%, -50%) scale(.8); */
    /* width: 90%; */
  }

  .hbt-Home .hbt-Home-cards .hbt-ImageLink,
  .hbt-NotFound .hbt-Home-cards .hbt-ImageLink {
    margin-bottom: var(--spacing-stack-md);
  }
}

@media screen and (max-width: 580px) {
  .hbt-Home .hbt-Banner .hbt-Banner-item .hbt-Banner-item-content {
    transform: translate(-20%, -50%) scale(.5);
    width: 90%;
  }
}
:root {
  --color-brand-primary-darkest: #67160D;
  --color-brand-primary-dark: #A61D19;
  --color-brand-primary-medium: #E3262E;
  --color-brand-primary-light: #FE918D;
  --color-brand-primary-lightest: #FDE6E3;
  --color-neutral-lightest: #FFFFFF;
  --color-neutral-light: #F8F8F8;
  --color-neutral-medium: #D4D7DE;
  --color-neutral-dark: #51555D;
  --color-neutral-darkest: #000000;
  --font-weight-semiBold: 600;
  --font-weight-regular: 400;
  --font-size-xxxs: 12px;
  --font-size-xxs: 16px;
  --font-size-xs: 18px;
  --font-size-sm: 20px;
  --font-size-md: 22px;
  --font-size-lg: 24px;
  --font-size-xl: 26px;
  --font-size-xxl: 28px;
  --font-size-xxxl: 32px;
  --font-size-ul: 40px;
  --font-size-uul: 64px;
  --font-line-height-none: 0;
  --font-line-height-xxxs: 16px;
  --font-line-height-xxs: 20px;
  --font-line-height-xs: 22px;
  --font-line-height-sm: 24px;
  --font-line-height-md: 26px;
  --font-line-height-lg: 28px;
  --font-line-height-xl: 30px;
  --font-line-height-xxl: 32px;
  --font-line-height-xxxl: 36px;
  --font-line-height-ul: 44px;
  --font-line-height-uul: 68px;
  --border-width-none: 0;
  --border-width-thin: 1px;
  --border-width-medium: 2px;
  --border-width-thick: 4px;  
  --border-radius-none: 0;
  --border-radius-xs: 4px;
  --border-radius-sm: 8px;
  --border-radius-md: 16px;
  --border-radius-lg: 24px;
  --border-radius-pill: 500px;
  --border-radius-circle: 50%;
  --spacing-inline-xxxs: 4px;
  --spacing-inline-xxs: 8px;
  --spacing-inline-xs: 16px;
  --spacing-inline-sm: 24px;
  --spacing-inline-md: 32px;
  --spacing-inline-lg: 48px;
  --spacing-inline-xl: 64px;
  --spacing-inset-xxs: 4px;
  --spacing-inset-xs: 8px;
  --spacing-inset-sm: 16px;
  --spacing-inset-md: 24px;
  --spacing-inset-lg: 32px;
  --spacing-inset-xl: 48px;
  --spacing-squish-xxxs: 4px 8px;
  --spacing-squish-xxs: 4px 16px;
  --spacing-squish-xs: 8px 16px;
  --spacing-squish-sm: 8px 24px;
  --spacing-squish-md: 16px 24px;
  --spacing-squish-lg: 16px 32px;
  --spacing-squish-xl: 24px 32px;
  --spacing-squish-xxl: 32px 48px;
  --spacing-stack-xxxs: 4px;
  --spacing-stack-xxs: 8px;
  --spacing-stack-xs: 16px;
  --spacing-stack-sm: 24px;
  --spacing-stack-md: 32px;
  --spacing-stack-lg: 48px;
  --spacing-stack-xl: 64px;
  --spacing-stack-xxl: 96px;
  --spacing-stack-xxxl: 128px;
}
.hbt-Heading {
  font-weight: var(--font-weight-semiBold);
}
.hbt-Heading.hbt-Heading--xxxs {
  font-size: var(--font-size-xxxs);
}
.hbt-Heading.hbt-Heading--xxs {
  font-size: var(--font-size-xxs);
}
.hbt-Heading.hbt-Heading--xs {
  font-size: var(--font-size-xs);
}
.hbt-Heading.hbt-Heading--sm {
  font-size: var(--font-size-sm);
}
.hbt-Heading.hbt-Heading--md {
  font-size: var(--font-size-md);
}
.hbt-Heading.hbt-Heading--lg {
  font-size: var(--font-size-lg);
}
.hbt-Heading.hbt-Heading--xl {
  font-size: var(--font-size-xl);
}
.hbt-Heading.hbt-Heading--xxl {
  font-size: var(--font-size-xxl);
}
.hbt-Heading.hbt-Heading--xxxl {
  font-size: var(--font-size-xxxl);
}
.hbt-Heading.hbt-Heading--ul {
  font-size: var(--font-size-ul);
}
.hbt-Heading.hbt-Heading--uul {
  font-size: var(--font-size-uul);
}

.hbt-Heading--inverse {
  color: var(--color-neutral-lightest);
}
.hbt-Container {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 0 var(--spacing-inline-xs);
  box-sizing: border-box;
}
.hbt-Link, .hbt-Topic {
  color: var(--color-neutral-darkest);
  text-decoration: none;
  font-weight: var(--font-weight-semiBold);
  align-items: center;
  display: flex;
}

.hbt-Link--inverse, .hbt-Topic--inverse {
  color: var(--color-neutral-lightest);
}

.hbt-Link:hover .hbt-Link-content {
  text-decoration: underline;
}

.hbt-Link .hbt-Link-icon,
.hbt-Topic .hbt-Topic-icon {
  margin-right: var(--spacing-inline-xxs);
  text-decoration: none;
}

.hbt-Link .hbt-Link-content+.hbt-Link-icon,
.hbt-Topic .hbt-Topic-content+.hbt-Topic-icon {
  margin-left: var(--spacing-inline-xxs);
}
.hbt-Menu,
.hbt-Menu .hbt-Menu-left {
  display: flex;
  align-items: center;
}

.hbt-Menu {
  justify-content: space-between;
  flex-direction: row;
  height: 70px;
  background-color: var(--color-neutral-lightest);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  box-shadow: 0 0 40px -30px;
}

.hbt-Menu .hbt-Container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.hbt-Menu .hbt-Menu-left nav ul,
.hbt-Menu .hbt-Menu-right ul {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.hbt-Menu .hbt-Menu-left-logo {
  margin-right: var(--spacing-inline-xs);
  height: 50px;
}

.hbt-Menu .hbt-Menu-left-navigation.hbt-isActive {
  border-bottom: var(--border-width-thick) solid var(--color-brand-primary-dark);
  padding-top: var(--spacing-stack-xxxs);
}

.hbt-Menu .hbt-Menu-left nav ul li a,
.hbt-Menu .hbt-Menu-right ul li a {
  padding: var(--spacing-inset-md);
  display: flex;
}

@media screen and (max-width: 1024px){
  .hbt-Menu .hbt-Menu-left,
  .hbt-Menu .hbt-Container,
  .hbt-Menu .hbt-Menu-left nav ul,
  .hbt-Menu .hbt-Menu-right ul {
    flex-direction: column;
    align-items: normal;
  }

  .hbt-Menu > a {
    margin-left: var(--spacing-inline-xs);
  }

  .hbt-Menu .hbt-Container {
    background-color: var(--color-neutral-lightest);
    width: 70vw;
    position: fixed;
    right: -70vw;
    top: 0;
    height: 100%;
    box-shadow: 0 0 40px -30px;
    padding-top: 70px; /* Header height */
    transition: all 0.2s ease-in;
  }

  .hbt-Menu .hbt-Menu-toggleButton {
    background-color: var(--color-neutral-lightest);
    border: none;
    height: 100%;
    padding: var(--spacing-inset-md);
    font-size: var(--font-size-lg);
    position: relative;
    z-index: 20;
    color: var(--color-neutral-darkest);
  }
  .hbt-Menu .hbt-Menu-toggleButton:active {
    opacity: var(--opacity-medium);
  }

  .hbt-Menu .hbt-Menu-left-navigation.hbt-isActive {
    border-bottom: none;
  }

  .hbt-Menu .hbt-Menu-left-navigation.hbt-isActive a {
    color: var(--color-brand-primary-dark);
  }

  .hbt-Menu .hbt-Menu-container.hbt-isActive {
    right: 0;
  }

}
.hbt-Banner {
  background-color: var(--color-brand-primary-medium);
  margin-bottom: var(--spacing-stack-lg);
  max-height: 610px;
  overflow: hidden;
}

.hbt-Banner .hbt-Banner-item {
  position: relative;
  max-height: 610px;
  overflow: hidden;
}

.hbt-Banner .hbt-Banner-item:after {
  content: " ";
  position: absolute;
  width: 100%;
  background: url(/static/media/wave.f4a6d6f5.svg) no-repeat center bottom/100%;
  height: 100%;
  bottom: 0;
  left: 0;
}

.hbt-Banner .hbt-Banner-item .hbt-Banner-item-image {
  width: 100%;
  max-height: 60vh;
  object-fit: cover;
  display: flex;
  margin: auto;
  object-position: center;
}

.hbt-Banner .hbt-Banner-item .hbt-Banner-item-content {
  position: absolute;
  width: 60%;
  height: 100%;
  top: 45%;
  left: 35%;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.hbt-Text {
  color: var(--color-neutral-dark);
  text-decoration: none;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-xxs);
  line-height: var(--font-line-height-xxs);
}

.hbt-Text--inverse {
  color: var(--color-neutral-lightest);
}

.hbt-Text--bold {
  font-weight: var(--font-weight-semiBold);
}
.hbt-Footer {
  padding: var(--spacing-inset-sm);
  background-color: var(--color-brand-primary-medium);
  text-align: center;
  margin-top: var(--spacing-stack-lg);
}
.hbt-ImageLink {
  display: flex;
  flex-direction: column-reverse;
  align-items: baseline;
  padding: var(--spacing-inset-xs);
  width: 357px;
  height: 238px;
  position: relative;
  border-radius: var(--border-radius-xs);
  overflow: hidden;
  border: var(--border-width-medium) solid transparent;
  transition: all ease-in 300ms;
}

.hbt-ImageLink:hover {
  /* border: var(--border-width-medium) solid var(--color-neutral-medium); */
}

.hbt-ImageLink:hover .hbt-ImageLink-img {
  transform: scale(1.02);
}

.hbt-ImageLink .hbt-ImageLink-img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
  object-position: top;
  transition: all ease-in 200ms;
}

.hbt-ImageLink .hbt-ImageLink-highlight {
  z-index: 2;
}

.hbt-ImageLink .hbt-ImageLink-highlight .hbt-Heading {  
  background-color: var(--color-neutral-darkest);
  padding: var(--spacing-inset-xs);
  border-radius: var(--border-radius-xs);
  display: flex;
}

.hbt-ImageLink .hbt-ImageLink-highlight .hbt-Heading .hbt-ImageLink-highlight-icon {
  padding-left: var(--spacing-inline-xxxs);
  transition: all 100ms ease-in;
}

.hbt-ImageLink:hover .hbt-ImageLink-highlight .hbt-Heading .hbt-ImageLink-highlight-icon {
  padding-left: var(--spacing-inline-xxs);
}
@media screen and (min-width: 1440px) {
  .hbt-About .hbt-Banner {
    background: var(--color-neutral-dark);
  }
  .hbt-About .hbt-Banner .hbt-Banner-item .hbt-Banner-item-image {
    max-width: 1920px;
  }  
}
.hbt-Subtitle {
  font-weight: var(--font-weight-semiBold);
  color: var(--color-neutral-dark);
}
.hbt-Subtitle.hbt-Subtitle--xxxs {
  font-size: var(--font-size-xxxs);
}
.hbt-Subtitle.hbt-Subtitle--xxs {
  font-size: var(--font-size-xxs);
}
.hbt-Subtitle.hbt-Subtitle--xs {
  font-size: var(--font-size-xs);
}
.hbt-Subtitle.hbt-Subtitle--sm {
  font-size: var(--font-size-sm);
}
.hbt-Subtitle.hbt-Subtitle--md {
  font-size: var(--font-size-md);
}
.hbt-Subtitle.hbt-Subtitle--lg {
  font-size: var(--font-size-lg);
}
.hbt-Subtitle.hbt-Subtitle--xl {
  font-size: var(--font-size-xl);
}
.hbt-Subtitle.hbt-Subtitle--xxl {
  font-size: var(--font-size-xxl);
}
.hbt-Subtitle.hbt-Subtitle--xxxl {
  font-size: var(--font-size-xxxl);
}
.hbt-Subtitle.hbt-Subtitle--ul {
  font-size: var(--font-size-ul);
}

.hbt-Subtitle.hbt-Subtitle--ul {
  font-size: var(--font-size-ul);
}

.hbt-Subtitle--inverse {
  color: var(--color-neutral-lightest);
}
.hbt-Article .hbt-Heading,
.hbt-Article .hbt-Subtitle {
  margin-bottom: var(--spacing-stack-xs);
}

.hbt-Article .hbt-Article-content {
  margin-bottom: var(--spacing-stack-md);
  /* justify-content: center;
  display: flex;
  flex-direction: column; */
}

.hbt-Article .hbt-Article-content .hbt-Text+.hbt-Text {
  margin-top: var(--spacing-stack-xxxs);
}
.hbt-Contact .hbt-Banner .hbt-Banner-item .hbt-Banner-item-image {
  max-width: 1024px;
}

@media screen and (min-width: 1440px) {
 .hbt-Contact .hbt-Banner .hbt-Banner-item .hbt-Banner-item-image {
   max-width: 1920px;
 }  
}

.hbt-Contact .hbt-Banner .hbt-Banner-item .hbt-Banner-item-image {
  max-width: 1024px;
}

@media screen and (min-width: 1440px) {
 .hbt-Contact .hbt-Banner .hbt-Banner-item .hbt-Banner-item-image {
   max-width: 1920px;
 }  
}

.hbt-Form {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 1024px) {
  .hbt-Form {
    max-width: 50%;
  }
}

.hbt-Form .hbt-Form-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hbt-Input {
  display: flex;
  flex-direction: column;
  /* padding-bottom: var(--spacing-stack-md); */
  position: relative;
  margin-bottom: var(--spacing-stack-xs);
}

.hbt-Input .hbt-Input-inputField {
  height: 44px;
  font-size: var(--font-size-xs);
  border-radius: var(--border-radius-xs);
  border: var(--border-width-thin) solid var(--color-neutral-dark);
  padding: 0 var(--spacing-inline-xs);
}

.hbt-Input .hbt-Input-inputLabel {
  font-weight: var(--font-weight-semiBold);
  margin-bottom: var(--spacing-stack-xxxs);
}

.hbt-Input.hbt-Input--error .hbt-Input-errorMessage {
  display: block;
}

.hbt-Input .hbt-Input-errorMessage {
  margin-top: var(--spacing-stack-xxxs);
  margin-bottom: var(--spacing-stack-xxxs);
  color: red;
  font-size: var(--font-size-xxxs);
  display: none
}

.hbt-Form-submit {
  border-radius: var(--border-radius-pill);
  background-color: var(--color-brand-primary-medium);
  color: var(--color-neutral-lightest);
  border: none;
  font-size: var(--font-size-xs);
  display: flex;
  align-self: flex-end;
  padding: var(--spacing-squish-sm);
  font-weight: var(--font-weight-semiBold);
  cursor: pointer;
}

.hbt-Form-submit:disabled {
  cursor: not-allowed;
  background-color: var(--color-neutral-medium);
}

.hbt-Form-submit:hover {
  background-color: var(--color-brand-primary-dark);
}
.hbt-NotFound .hbt-Banner .hbt-Banner-item .hbt-Banner-item-image {
  max-width: 1024px;
}

@media screen and (min-width: 1440px) {
 .hbt-NotFound .hbt-Banner .hbt-Banner-item .hbt-Banner-item-image {
   max-width: 1920px;
 }  
}

